$fontSize:18px;

$widgetMargin:5px;
$widgetPadding:10px;


$columnsSm:1;
$columnsMd:2;
$columnsLg:5;
$columnsXL:8;
