
// TOPBAR
.displayTopBar {
}

.profileTopBar {
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
}

.searchTopBar {
  grid-area: search-top-bar;
  overflow: hidden;
}

.actionTopBar {
  grid-area: action-top-bar;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
}

// BOTTOM
.displayBottomBar {
  //padding: $widgetPadding;
  grid-area: bottom-bar;
  overflow: hidden;

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
}


// DESK

.displayDesk {
  //padding: $widgetPadding;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

// TABLET
@media (min-width: 610px) {
  .displayTopBar {
  }

  .actionTopBar {
    flex-flow: row wrap;
    justify-content: flex-start;
  }

  .displayBottomBar {
    flex-flow: row wrap;
    justify-content: flex-start;
  }
}

// DESKTOP
@media (min-width: 1000px) {
}