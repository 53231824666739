$backgroundColor: map-get($grey, '900');
$surface01BackgroundColor: lighten($backgroundColor, 5%);
$surface02BackgroundColor: lighten($backgroundColor, 7%);
$surface03BackgroundColor: lighten($backgroundColor, 8%);
$surface04BackgroundColor: lighten($backgroundColor, 9%);

$onBackgroundColor: #ffffff;
$onSurfaceColor: #ffffff;

$primaryColor: map-get($deep-purple, '200');
$primaryVariantColor: map-get($deep-purple, '700');
$secondaryColor: map-get($teal, '200');
$errorColor: map-get($red, '800');

$widgetPrimaryBorderColor: lighten($onSurfaceColor, 90%);
$widgetSecondaryBorderColor: lighten($secondaryColor, 50%);
$widgetErrorBorderColor: lighten($errorColor, 50%);