
.form-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: center;
}

.form-field-switch {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: flex-start;
}

input {
  background-color: $surface02BackgroundColor;
  color: $onSurfaceColor;
  border: none;
  border-bottom: 1px dotted $secondaryColor;
  margin: 5px;
  outline: none;
  padding: 5px;
  border-radius: 0;
  font-size: 1em;
}

label {
  font-weight: 700;
  font-size: 0.7em;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-left: 7px;
}

input::-webkit-input-placeholder {
  font-size: 18px;
}

input:hover {
  background-color: $surface02BackgroundColor;
  outline: 1px dotted $secondaryColor;
}

input[type=text]:focus {
  background-color: $surface02BackgroundColor;
  outline: 1px dotted $secondaryColor;
}

input[type=checkbox] {
  visibility: hidden;
}

.input-switch {
  width: 2em;
  height: 1.1em;
  background-color: inherit;
  border: 1px dotted $onBackgroundColor;
  border-radius: 2px;
  position: relative;
  padding: 0;
  margin: 5px
}

.input-switch:after {
}

.input-switch label {
  display: block;
  margin: 0;
  width: 60%;
  height: 100%;
  border-radius: 2px;
  transition: all .4s ease;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: $primaryColor;
}

.input-switch input[type=checkbox]:checked +label {
  left: 40%;
  background-color: $secondaryColor;
}


@media (min-width: 600px) {
  input {

  }

  label {

  }
}