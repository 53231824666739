$morphTransparency: 0.2;
//$morphBlur: 4;
$morphBlur: 4px;
$viewDetailBackgroundColor: #FFFFFF;
.view {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 20px;
  margin-right: 20px;
  margin-top: 20px;
  padding: 10px;

  background: rgba(red($surface01BackgroundColor), green($surface01BackgroundColor), blue($surface01BackgroundColor), $morphTransparency);
  backdrop-filter: blur($morphBlur);
  -webkit-backdrop-filter: blur($morphBlur);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.view-actions {
  width: 100%;
  //border-bottom: $onBackgroundColor solid 1px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;

  &.left {
    justify-content: flex-start;
  }
}

.view-action {
  width: 24px;
  height: 24px;
  text-align: center;
}


.view-content {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;
  margin-top: 5px;

  & .view-side {
    margin-right: 5px;
    padding-right: 5px;

    & .view-item-group {
      margin-top: 0.5em;
    }

    & .view-item {
      font-size: 0.8em;
    }
  }
}

article.view-article {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.view-detail {
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin: 10px;
  padding: 10px;
  border-radius: 20px;
  background: rgba(red($surface02BackgroundColor), green($surface02BackgroundColor), blue($surface02BackgroundColor), $morphTransparency);
  backdrop-filter: blur($morphBlur);
  -webkit-backdrop-filter: blur($morphBlur);

  & .view-item-group {
    margin-top: 1em;
    text-transform: uppercase;
    font-size: 0.8em;
  }

  & .view-item {
    font-size: 0.8em;
  }
}

.viewtext {
  margin-bottom: 0.5em;

  &.column {
    display: grid;
    grid-template-rows: 1em auto;
  }

  &.row {
    display: grid;
    grid-template-columns: 5em auto;
  }
}

.viewtext-title {
  font-weight: 700;
  letter-spacing: 3px;
  text-transform: uppercase;
}