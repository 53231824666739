table.table-density {
  border-collapse: collapse;
  font-size: 0.8em;
  margin-right: 1em;
  padding-right: 1em;
  border-right: darken($onSurfaceColor, 50%) solid 1px;

  &:last-child {
    margin-right: 0;
    padding-right: 0;
    border-right: none;
  }

  & td {

  }

  & thead {
    background: rgba(red($surface01BackgroundColor), green($surface01BackgroundColor), blue($surface01BackgroundColor), $morphTransparency+0.2);;
  }

  & tbody {

  }

  & tr {

  }

  & td {
    padding-right: 10px;

    &:last-child {

    }
  }

}
