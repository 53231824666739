body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, 'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $fontSize;
  background-color: $backgroundColor;
  color: $onSurfaceColor;
}


hr {
  border: 1px dotted $onBackgroundColor;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.landing-page-message {
  fill: $onBackgroundColor;
  stroke: none;

  & .hidden {
    fill: $backgroundColor;
  }

  & line {
    stroke: $onBackgroundColor;
  }

}

.scene-1 {
  background-image: radial-gradient(darken($onBackgroundColor, 70%), 1px, transparent 0);
  background-size: 20px 20px;
  background-position: 5px 10px;
  min-width: 100%;
  min-height: 100%;
  padding: 20px;
  font-size: 18px;
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

svg {
  color: $onBackgroundColor;

}


.avatar {
  width: 42px;
  height: 42px;
  border-radius: 50%;
}

.touch {
  cursor: pointer;
}

.action {
  cursor: pointer;

  &:hover {
    color: $backgroundColor;
    background-color: $secondaryColor;
    border-radius: 2px;
  }
}

.active {
  color: $backgroundColor;
  background-color: $primaryColor;
  border-radius: 2px;
}


.no-detail {
  padding-right: 5px;
  border-right: darken($onBackgroundColor, 20%) solid 1px;
}

.flex.row{
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
}